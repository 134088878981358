<template>
    <div>
        <custom-modal />
        <router-view />
        <v-dialog persistent="true" class="dialog" />
        <div class="loading-section" v-if="showLoader">
            <p>{{ loadingText }}</p>
        </div>

        <Dialog :visible="isModalVisible" modal :header="errorHeader" :style="{ width: '50vw' }"
            :breakpoints="{ '960px': '75vw', '641px': '100vw' }" @update:visible="isModalVisible = false" :pt="{
                root: { class: 'bg-white text-dark p-4 shadow' }
            }">
            <p v-html="errorMessage" class="py-2"></p>
        </Dialog>
    </div>
</template>

<script>
import { ref, onBeforeUnmount } from 'vue';
import store from "./store";
import mitt from "mitt";
import Dialog from 'primevue/dialog';

export default {
    name: "App",
    components: {
        Dialog
    },
    setup() {
        const loadingText = ref('loading...');
        const showLoader = ref(false);
        const errorMessage = ref('');
        const errorHeader = ref('');
        const isModalVisible = ref(false);

        const showErrorDialog = (error) => {
            isModalVisible.value = true;
            errorMessage.value = error.message;
            errorHeader.value = error.title ? error.title : 'Error';
        };

        const unsubscribe = store.subscribe((mutation, state) => {
            if (mutation.type === "updateError" && state.error.message) {
                showErrorDialog(state.error);
            } else if (mutation.type === "updateAlert" && state.alert) {
                isModalVisible.value = true;
                errorMessage.value = state.alert.message;
                errorHeader.value = state.alert.title;
            }
        });

        const emitter = mitt();

        emitter.on('showLoader', (loadingText) => {
            loadingText.value = loadingText;
            showLoader.value = true;
        });

        emitter.on('hideLoader', () => {
            showLoader.value = false;
        });

        onBeforeUnmount(() => {
            unsubscribe();
        });

        return {
            loadingText,
            showLoader,
            showErrorDialog,
            emitter,
            errorMessage,
            errorHeader,
            isModalVisible
        }
    }
};
</script>

<style lang="scss">
@import "assets/scss/styles";

.loading-section {
    position: fixed;
    height: 100vh;
    width: 100%;
    inset: 0;
    background: rgba(0, 0, 0, 0.6);
    display: grid;
    place-items: center;

    p {
        font-size: 1rem;
        color: #fff;
    }
}
</style>
