<template>
    <section>
        <div class="py-5 container alumni">
            <div class="row">
                <div v-if="isScholarship" class="col-12 col-md-4 mb-3">
                    <p class="hero__heading">Our scholarship <span class="text-blue-gradient">sponsors</span></p>
                </div>
                <div v-else class="col-12 col-md-4 mb-3">
                    <p class="hero__heading">Where our <span class="text-blue-gradient">Alumni </span> currently work</p>
                    <p>We have a strong Alumni community base to reach out to for mentorship</p>
                    <router-link role="button" :to="{ name: 'TrackApplicationPage' }"
                        class="btn btn-brand-primary custom-spacing justify-content-center">
                        Get Started <span class="px-1"><img src="@/assets/img/arrow-up-right.svg"
                                alt="arrow pointing right"></span>
                    </router-link>
                </div>
                <div class="col-12 col-md-8 mb-3">
                    <div class="row">
                        <div class="col-6 d-grid alumni__company-logo" :class="company.extraClass"
                            v-for="(company, index) in alumniCompanies" :key="index">
                            <img :src="company.logo" :alt="company.name" class="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AlumniCommunity",
    props: {
        isScholarship: { type: Boolean, default: false }
    },
    data() {
        return {
            alumniCompanies: this.isScholarship ? [
                { extraClass: "align-content-end justify-content-center", logo: require("@/assets/img/brand/bongoHive.svg"), name: "Bongo Hive" },
                { extraClass: "justify-content-center", logo: require("@/assets/img/brand/ahkColored.svg"), name: "AHK" },
                { extraClass: "align-content-end justify-content-center m-4", logo: require("@/assets/img/brand/ukNigeriaTechHub.svg"), name: "UKNG Tech Hub" },
            ] : [
                { extraClass: "align-content-end justify-content-end", logo: require("@/assets/img/brand/amazon.svg"), name: "Amazon" },
                { extraClass: "justify-content-center", logo: require("@/assets/img/brand/kudi.svg"), name: "Kudi" },
                { extraClass: "align-content-end justify-content-center", logo: require("@/assets/img/brand/capitalone.svg"), name: "Capital One" },
                { extraClass: "align-content-center justify-content-center", logo: require("@/assets/img/brand/deliveryhero.svg"), name: "Delivery Hero" },
                { extraClass: "align-content-end justify-content-start", logo: require("@/assets/img/brand/interswitch.svg"), name: "Interswitch" },
                { extraClass: "align-content-center justify-content-start", logo: require("@/assets/img/brand/cowrywise.svg"), name: "Cowrywise" },
            ]
        }
    }
}
</script>