import mutations from "./mutations";
import actions from "./actions";

const getDefaultAuthStates = () => {
    return {
        password: "",
        customerPhoneNumber: null,
        customerEmail: null,
        otpSent: false,
        otp: "",
        otpVerified: false,
        otpError: null,
        changePinState: null,
    };
};

const state = getDefaultAuthStates();

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getDefaultAuthStates,
};
