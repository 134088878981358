const setApplicantPrograms = (state, applicantPrograms) => {
    state.applicantPrograms = applicantPrograms;
};

const getScholarProfile = (state, scholarData) => {
    state.scholarsData = scholarData;
};
const getApplicantCertificates = (state, applicantCertificates) => {
    state.applicantCertificates = applicantCertificates;
};

const mutateScholarImage = (state, scholarImage) => {
    state.scholarsData.selfie_url = scholarImage;
};

const mutateGithubAccessToken = (state, payload) => {
    state.githubTokenGenerateState = payload;
};

const mutateLinkGithubAccount = (state, payload) => {
    state.scholarsData.push(payload);
};

const setApplicantTopics = (state, applicantTopics) => {
    applicantTopics.forEach((topicData) => {
        state.applicantQuizzes.forEach((quiz) => {
            if (quiz.topic === topicData.name) {
                let quizs = "quizStatus";
                topicData[quizs] = quiz.status;
                return topicData;
            }
        });
    });
    state.applicantTopics = applicantTopics;
};

const setRegistrationId = (state, registrationId) => {
    state.registrationId = registrationId;
};

const mutateApplicantTask = (state, payload) => {
    state.applicantTaskState = payload;
};

const mutateSaveApplicantTask = (state, payload) => {
    state.saveApplicantTaskState.push(payload);
};

const setApplicantQuizzes = (state, applicantQuizzes) => {
    state.applicantQuizzes = applicantQuizzes;
};

const loadingStatus = (state, newLoadingStatus) => {
    if (state.applicantPrograms.length > 0) {
        state.loadingStatus = false;
    } else {
        state.loadingStatus = newLoadingStatus;
    }
};

const loadingNavigation = (state, newLoadingNavigation) => {
    if (state.applicantTopics.length > 0) {
        state.loadingStatus = false;
    } else {
        state.loadingNavigation = newLoadingNavigation;
    }
};
const mutateIsLoadingVerify = (state, newIsLoading) => {
    state.isLoadingVerify = newIsLoading;
};
const mutateIsLoadingScholarshipReg = (state, scholarLoading) => {
    state.isLoadingScholarshipReg = scholarLoading;
};
const mutateIsLoadingPaidReg = (state, paidLoading) => {
    state.isLoadingPaidReg = paidLoading;
};
const mutateApplicantAccess = (state, payload) => {
    state.applicantAccessState = payload;
};

const setEnrollApplicant = (state, payload) => {
    state.applicantEnrollStatus = payload;
};

const mutateFreeScholarshipRegistration = (state, payload) => {
    state.applicantEnrollStatus = payload;
};

const mutateVerifyScholarExist = (state, payload) => {
    state.verifyScholarExistState = payload;
};

const setSaveScholarPaidRegistration = (state, payload) => {
    state.saveScholarPaidRegistration.push(payload);
};

const setApplicantDataByEmail = (state, applicantDataByEmail) => {
    state.applicantDataByEmail = applicantDataByEmail;
};

export default {
    getScholarProfile,
    mutateScholarImage,
    mutateGithubAccessToken,
    mutateLinkGithubAccount,
    setApplicantPrograms,
    setApplicantTopics,
    setRegistrationId,
    mutateApplicantTask,
    mutateSaveApplicantTask,
    setApplicantQuizzes,
    loadingStatus,
    loadingNavigation,
    mutateIsLoadingVerify,
    mutateIsLoadingScholarshipReg,
    mutateIsLoadingPaidReg,
    mutateApplicantAccess,
    setEnrollApplicant,
    mutateFreeScholarshipRegistration,
    mutateVerifyScholarExist,
    setSaveScholarPaidRegistration,
    setApplicantDataByEmail,
    getApplicantCertificates,
};
