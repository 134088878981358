import { RepositoryFactory } from "../../../repo/RepositoryFactory";
import { utils } from "../../../utils";
import router from '@/router'

const accountRepository = RepositoryFactory.get("account");
let responseCodes = utils.constants.responseCodes;
const login = async (context, requestBody) => {
    try {
        let phoneNumber = requestBody.phone_number;
        let password = requestBody.password;
        let response = await accountRepository.login(phoneNumber, password);
        let responseData = response.data;
        if (response.status === responseCodes.success && responseData.status === "success") {
            context.commit("setCustomerPhoneNumber", responseData.data.phone_number);
            context.commit("setUserData", responseData.data, { root: true });
        } else if (responseCodes.invalidToken.includes(response.status)) {
            if (responseData.message === "Your pin is incorrect") {
                utils.misc.showError(context, "Phone Number/Password Mismatch");
            } else if (responseData.code === "invalid_parameters") {
                utils.misc.showError(
                    context,
                    "We couldn't find you on our system. Please contact support or apply to one of our courses."
                );
            } else {
                utils.misc.onAuthTokenExpired(context, "auth/login", requestBody);
            }
        } else if (!responseData.message) {
            utils.misc.showInternalError(context);
            utils.misc.showHideLoading(context, false);
        } else {
            utils.misc.showGeneralError(context, responseData.message);
        }
    } catch (err) {
        utils.misc.catchNetworkError(context);
    }
};


const sendOtp = async (context, params) => {
    context.commit('updateOtpSent', false);
    context.commit('updateOtpError', null);

    await accountRepository.sendOtp(params)
        .then((response) => {
            let responseData = response.data;
            if (response.status === responseCodes.success && responseData.status === "success") {
                router.push({ path: `/reset/verify-otp`, query:{ phoneNumber: params }})
                let data = responseData.data;
                context.commit('updateCustomerEmail', data.email);
                context.commit('updateError', { title: '', message: '' }, { root: true });
                context.commit('updateOtpSent', data.otp_sent);
                if (!data.otp_sent || data.page === "reset_password") {
                    context.commit('updateOtpError', { page: data.page, message: data.message });
                }
                else {
                    context.commit('updateOtpSent', data);
                }
            } else if (response.status === responseCodes.invalidToken) {
                utils.misc.onAuthTokenExpired(context, "auth/sendOtp", params);
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
            utils.misc.showHideLoading(context, false);
        }).catch(() => {
            utils.misc.catchNetworkError(context);
            context.commit('updateOtpSent', false);
            utils.misc.showHideLoading(context, false);
        })
};

const verifyOtp = async (context, otp) => {
    utils.misc.showHideLoading(context, true);
    await accountRepository.verifyOtp(otp)
        .then((response) => {
            let responseData = response.data;
            if (responseData.status === "success") {
                context.commit('updateOtpVerified', responseData.data)
            }
            else if (response.status === responseCodes.invalidToken) {
                utils.misc.onAuthTokenExpired(context, "auth/verifyOtp", otp);
                context.commit('updateOtpVerified', false);
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
                context.commit('updateOtpVerified', false);
            }
            utils.misc.showHideLoading(context, false);
        }).catch(() => {
            utils.misc.catchNetworkError(context);
            context.commit('updateOtpVerified', false);
            utils.misc.showHideLoading(context, false);
        })
};


const changePin = async (context, scholarNewPin) => {
    let userAccessToken = context.rootState.home.token
    await accountRepository.changePin(scholarNewPin, userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === responseCodes.success && responseData.status === "success") {
                context.commit('changePinMutation', responseData.data)
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, 'changePinMutation');
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
};

export default {
    login,
    sendOtp,
    verifyOtp,
    changePin,
};
