import axios from "axios";

const axiosInstance = axios.create({
	baseURL: process.env.VUE_APP_NEW_API_URL,
	validateStatus: function (status) {
		return !(status < 500 && status >= 600);
	}
});

// Request interceptor to include Bearer token in headers
axiosInstance.interceptors.request.use(config => {
    try {
        // Retrieve and parse the local storage data
        let localStorageData = localStorage.getItem("vuex");
        let parseData = localStorageData ? JSON.parse(localStorageData) : {};

        // Initialize 'home' if it doesn't exist
        if (!parseData.home) {
            parseData.home = {};
        }

        const token = parseData.home.newBackendToken; // Safely access the token

        // Add the Authorization header if the token exists
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    } catch (error) {
        console.error("Error retrieving or parsing local storage data:", error);
        // Handle the error appropriately (e.g., show notification to the user)
    }

    return config;
}, error => {
    return Promise.reject(error);
});


axiosInstance.interceptors.response.use(response => { return response });

export default axiosInstance;
