import Repository from "./Repository";

const resource = "data";
let config = {
    headers: {
        "Content-Type": "application/json",
    },
};

let bootcampsAndPrograms = () => {
    return Repository.get(`${resource}/bootcamp-programs`, {}, config);
};

let allLessonsTrack = () => {
    return Repository.get(`${resource}/tracks-lessons`, config);
};

let allScholarshipBootcamp = () => {
    return Repository.get(`${resource}/scholarship-bootcamp`, {}, config);
};

let getCountriesAndState = () => {
    return Repository.get(`${resource}/country-states`, {}, config);
};

export default {
    bootcampsAndPrograms,
    allLessonsTrack,
    allScholarshipBootcamp,
    getCountriesAndState,
};
