import { createApp } from 'vue';
import { DateTime } from "luxon";
import misc from "../utils/misc";

const app = createApp({});

app.config.globalProperties.$filters = {
  commaSeparatedNumber(value) {
    return misc.getCommaSeparatedNumber(value);
  },
  formatDateTime(dateTime) {
    return DateTime.fromSQL(dateTime).toFormat('dd LLLL yyyy');
  },
  numberShorthand(number) {
    return misc.getNumberShorthand(number);
  },
  yearPlural(number) {
    if (number)
      return parseInt(number) === 1 ? "1 year" : number + " years";
    else
      return number;
  },
  charIndex(i) {
    return String.fromCharCode(97 + i);
  }
};

app.mount('#app');
