<template>
  <div class="row">
    <div class="col-12 col-md-10">
      <div class="row">
        <div
          v-for="(track, index) in tracks"
          :key="index"
          class="col-md-4 mb-3"
        >
          <div class="card avail-track-body h-100">
            <div class="card-body pt-4 pb-0 px-0">
              <div class="row">
                <span v-if="!partnerColor" class="icons text-blue-gradient">
                  <i class="bi" :class="track.classIcon"></i>
                </span>
                <span
                  v-if="partnerColor"
                  class="icons"
                  :style="{ color: partnerColor }"
                >
                  <i class="bi" :class="track.classIcon"></i>
                </span>
                <p class="card__header-text py-3 text-capitalize">
                  {{ track.cardTitle }}
                </p>
                <p class="card__sub-text mb-5">{{ track.cardText }}</p>
                <div>
                  <router-link
                    :to="track.link"
                    class="stretched-link card__sub-text fw-bold text-black"
                    >VIEW TRACK
                    <i class="bi bi-chevron-right"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-2 d-flex align-items-center view-more">
      <router-link v-if="isPartner" to="/partners" class="text-black">
        <span
          v-if="partnerColor"
          class="bi bi-arrow-right-circle"
          :style="{ color: partnerColor, fontSize: '60px' }"
        ></span>
        <span
          v-if="!partnerColor"
          class="bi bi-arrow-right-circle text-blue-gradient"
          :style="{ fontSize: '60px' }"
        ></span>
        <div>View more</div>
      </router-link>
      <router-link v-else :to="{ name: 'TracksListPage' }" class="text-black">
        <img
          src="@/assets/img/icons/arrow-right.svg"
          alt="arrow point right"
        /><br />
        <span>View more</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "AvailableTracksCard",
  props: {
    tracks: Array,
    partnerColor: String,
    isPartner: { type: Boolean, default: false },
  },
};
</script>
