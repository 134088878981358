<template>
    <div class="container-fluid school-header shadow">
        <div class="row">
            <div class="col col-md-6 bg-white rounded p-4">
                <li
                    v-for="school in schoolsList"
                    :key="school.id"
                    role="presentation"
                    @mouseover="getSchIndex(school.id)"
                    :class="{ 'inactive-school': !hasActiveTrack(school) }"
                    class="px-0 schools-list"
                >
                    <router-link
                        class="d-flex flex-wrap"
                        :to="
                            getSchoolDetailsRoute(
                                school.id,
                                school.bootcamp_name
                            )
                        "
                        v-if="hasActiveTrack(school)"
                    >
                        <span class="text-capitalize fw-bold pb-1">{{
                            school.bootcamp_name
                        }}</span>
                        <span class="text-gray">{{ school.desc }}</span>
                    </router-link>
                </li>
            </div>
            <div class="col col-md-6 p-4">
                <div
                    class="row"
                    v-for="(school, index) in schoolsList"
                    :key="index"
                >
                    <div v-if="schId == school.id">
                        <p class="schImgBox" v-if="school.bootcamp_img !== ''">
                            <img
                                :src="school.bootcamp_img"
                                class="img-fluid rounded"
                                alt="school img"
                            />
                        </p>
                        <p class="imgBox shadow bg-white" v-else>
                            <i class="bi bi-camera"></i>
                        </p>
                        <h4 class="pt-3">Tracks</h4>
                        <li
                            v-for="(track, index) in school.program"
                            :key="index"
                            class="pb-2"
                        >
                            <span
                                v-if="track.track_status == 'active'"
                                class="text-capitalize"
                                >{{ track.program_name }}</span
                            >
                        </li>
                    </div>
                </div>
            </div>
        </div>
        <custom-loader class="pb-4" v-if="isLoading" />
    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import CustomLoader from "../elements/CustomLoader.vue";

export default {
    name: "SchoolHeaderModal",
    components: {
        CustomLoader,
    },
    data() {
        return {
            hover: false,
            schId: null,
        };
    },
    async created() {
        try {
            await this.$store.dispatch("home/getAuthToken");
            await this.$store.dispatch("data/getBootcampsAndPrograms");
            await this.$store.dispatch("data/allLessonsTrack");
            await this.$store.dispatch("data/allScholarshipBootcamp");
        } catch (error) {
            console.log(error);
        }
    },
    methods: {
        getSchoolDetailsRoute(id, name) {
            return {
                name: "SchoolDetailsPage",
                params: name ? { id, name } : { id },
            };
        },
        getSchIndex(id) {
            this.schId = id;
        },
        hasActiveTrack(school) {
            return school.program.some(
                (track) => track.track_status === "active"
            );
        },
    },
    computed: {
        bootcampsAndPrograms() {
            return this.$store.state.bootcampsAndPrograms;
        },
        ...mapState("data", {
            bootcampsAndPrograms: "bootcampsAndPrograms",
            allLessonsTrack: "getDufunaTracks",
        }),
        ...mapGetters("data", {
            bootcampsAndPrograms: "bootcampsAndPrograms",
            getDufunaTracks: "getDufunaTracks",
        }),
        schoolsList() {
            return this.bootcampsAndPrograms.filter(
                (school) =>
                    school.has_partnership == false &&
                    school.is_bootcamp_paid == "paid" &&
                    school.is_bootcamp_active == "active"
            );
        },
        isLoading() {
            return this.schoolsList.length === 0;
        },
    },
    mounted() {
        this.schoolsList = this.bootcampsAndPrograms.filter(
            (school) =>
                school.has_partnership == false &&
                school.is_bootcamp_paid == "paid" &&
                school.is_bootcamp_active == "active"
        );
    },
};
</script>

<style lang="scss">
@import "../../assets/scss/pages/school-header-dropdown.scss";
</style>
