import { RepositoryFactory } from "../../../repo/RepositoryFactory";
import { utils } from "../../../utils";
const lessonsRepository = RepositoryFactory.get("lessons");
let responseCodes = utils.constants.responseCodes;

const lessonsVideo = async (context, lessonsId) => {
    let userAccessToken = context.rootState.home.token;
    await lessonsRepository.lessonsVideos(lessonsId, userAccessToken)
        .then(response => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === 200) {
                context.commit('setLessonsVideo', responseData.data);
                return responseData;
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, 'setLessonsVideo', lessonsId);
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
};

export default {
    lessonsVideo
};