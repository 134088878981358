import { RepositoryFactory } from "../../../repo/RepositoryFactory";
import { utils } from "../../../utils";
const dataRepository = RepositoryFactory.get("data");
let responseCodes = utils.constants.responseCodes;

const getBootcampsAndPrograms = async (context) => {
    let userAccessToken = context.rootState.home.token;
    await dataRepository
        .bootcampsAndPrograms(userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (
                responseStatus === responseCodes.success &&
                responseData.status === "success"
            ) {
                context.commit("setBootcampsAndPrograms", responseData.data);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "setBootcampsAndPrograms"
                );
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const allLessonsTrack = async (context) => {
    let userAccessToken = context.rootState.home.newBackendToken;
    await dataRepository
        .allLessonsTrack(userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (
                responseStatus === responseCodes.success &&
                responseData.status === "success"
            ) {
                context.commit("allLessonsTrackMutation", responseData.data);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "allLessonsTrackMutation"
                );
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const allScholarshipBootcamp = async (context) => {
    let userAccessToken = context.rootState.home.newBackendToken;
    await dataRepository
        .allScholarshipBootcamp(userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === 200) {
                context.commit(
                    "allScholarshipBootcampMutation",
                    responseData.data
                );
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "allScholarshipBootcampMutation"
                );
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const getCountriesAndState = async (context) => {
    let userAccessToken = context.rootState.home.token;
    await dataRepository
        .getCountriesAndState(userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === 200) {
                context.commit(
                    "getCountriesAndStateMutation",
                    responseData.data
                );
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "getCountriesAndStateMutation"
                );
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

export default {
    allLessonsTrack,
    allScholarshipBootcamp,
    getBootcampsAndPrograms,
    getCountriesAndState,
};
