<template>
    <section class="tracks-available pt-3 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-xl-10 mx-auto text-center">
                    <div class="row justify-content-center">
                        <p class="display__title pb-3">
                            <span>Make A Choice.</span><br>
                            <span v-if="!isPartnerColor" class="text-blue-gradient">Available Tracks</span>
                            <span v-else :style="{ color: partnerColor }">Available Tracks</span>
                        </p>
                        <p class="display__header-text">
                            {{ description }}
                        </p>
                    </div>
                    <div v-if="isPartnerTracksCard">
                        <available-tracks-card :tracks="this.tracks" routeUrl="/partners" :isPartner="true"
                            :partnerColor="this.partnerColor">
                        </available-tracks-card>
                    </div>

                    <div v-else>
                        <available-tracks-card :tracks="this.tracks" routeUrl="/all-tracks"></available-tracks-card>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import AvailableTracksCard from '../elements/AvailableTracksCard.vue';
import { mapGetters, mapState } from "vuex";
export default {
    components: { AvailableTracksCard },
    name: "AvailableTracks",
    props: {
        isPartnerColor: { type: Boolean, default: false },
        partnerColor: String,
        isPartnerTracksCard: { type: Boolean, default: false }
    },
    data: () => ({
        description: "No prior experience necessary to get started.",
        tracks: []
    }),
    computed: {
        ...mapState('data', {
            getDufunaTracks: 'getDufunaTracks',
        }),
        ...mapGetters('data', {
            getDufunaTracks: 'getDufunaTracks',
        }),
    },
    mounted() {
        function getMultipleRandom(arr, num) {
            const shuffled = [...arr].sort(() => 0.5 - Math.random());
            return shuffled.slice(0, num);
        }

        this.tracks = getMultipleRandom(this.getDufunaTracks, 3);
        this.tracks = this.tracks.map((i) => {
            let title = i.program;
            if (title.includes('Design')) {
                let icon = "bi-palette";
                return {
                    classIcon: icon,
                    cardTitle: i.program,
                    cardText: i.track_short_description,
                    link: { name: 'TrackDetailsPage', params: { programName: i.program }, }

                }
            } else if (title.includes('Product')) {
                let icon = "bi-bar-chart-line";
                return {
                    classIcon: icon,
                    cardTitle: i.program,
                    cardText: i.track_short_description,
                    link: { name: 'TrackDetailsPage', params: { programName: i.program }, }
                }
            } else {
                let icon = "bi-code-slash";
                return {
                    classIcon: icon,
                    cardTitle: i.program,
                    cardText: i.track_short_description,
                    link: { name: 'TrackDetailsPage', params: { programName: i.program }, }
                }
            }
        })
    },
};
</script>
