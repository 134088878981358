import { createApp } from "vue";
import App from "./App.vue";
import mitt from "mitt";
import router from "./router";
import { vfmPlugin } from "vue-final-modal";
import VueCountdown from "@chenfengyuan/vue-countdown";
import store from "./store";
import MazBtn from "maz-ui/components/MazBtn";
import MazInput from "maz-ui/components/MazInput";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/carousel";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./filters/filters";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import "maz-ui/css/main.css";
import VueGtag from "vue-gtag";

import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";

import { StoryblokVue, apiPlugin } from "@storyblok/vue";
import vueCountryRegionSelect from "vue-country-region-select";

const app = createApp(App);

// Import authService for fetching access token
import AuthRepository from "./repo/AuthRepository";

// Create an async function to initialize the app
const initializeApp = async () => {
  try {
    // Fetch the access token before mounting the app
    await AuthRepository.getAccessTokenFromNewBackend();
  } catch (error) {
    console.error('Failed to initialize app:', error);
  }
};

// Call initializeApp before mounting the app
initializeApp().then(() => {
  // Register global event emitter
  const emitter = mitt();
  app.config.globalProperties.emitter = emitter;

  app.use(vueCountryRegionSelect);

  // Use Vue Final Modal plugin
  app.use(vfmPlugin);

  app.use(PrimeVue);

  // Register global components
  app.component("MazBtn", MazBtn);
  app.component("MazInput", MazInput);
  app.component(VueCountdown.name, VueCountdown);
  app.component("MazPhoneNumberInput", MazPhoneNumberInput);

  // eslint-disable-next-line vue/multi-word-component-names
  app.component("Dialog", Dialog);

  // Set router and Google Analytics configurations
  app.config.globalProperties.$router = router;
  app.use(VueGtag, {
    config: [
      { id: process.env.VUE_APP_GOOGLE_ANALYTICS },
      { id: process.env.VUE_APP_YOUTUBE_ANALYTICS },
    ],
    appName: process.env.VUE_APP_TITLE,
    pageTrackerScreenviewEnabled: true,
  }, router);

  // Use StoryblokVue with access token
  app.use(StoryblokVue, {
    accessToken: "iU6EFzwNIsQtrQTKYgXyjwtt",
    use: [apiPlugin],
  });

  // Use Vuex store and Vue router
  app.use(store);
  app.use(router);

  // Mount the app
  app.mount("#app");
});
