const setSelectionTestQuestions = (state, selectionTestQuestions) => {
    state.selectionTestQuestions = selectionTestQuestions
};

const setSaveSelectionTestQuestions = (state, payload) => {
    state.saveSelectionTestQuestionState = payload
};

const setSelectionTestSession = (state, payload) => {
    state.selectionTestSession = payload
};

const loadingScore = (state, newLoadingNavigation) => {
    state.loadingNavigation = newLoadingNavigation
};

export default {
    setSelectionTestQuestions,
    setSaveSelectionTestQuestions,
    setSelectionTestSession,
    loadingScore
};
