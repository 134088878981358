const bootcampsAndPrograms = (state) => {
    return state.bootcampsAndPrograms;
};

const getAllLessonsTrack = (state) => {
    return state.allLessonsTrack;
};

const getSingleTrack = (state) => (trackId) => {
    return state.allLessonsTrack.find((track) => track.program_id === trackId);
};

const getDufunaTracks = (state) => {
    return state.allLessonsTrack.filter(
        (track) =>
            track.has_partnership === null && // Check if it's  null
            track.bootcamp_pricing_tag === "paid" &&
            track.track_status === "active"
    );
};

const getActiveScholarships = (state) => {
    const today = new Date();
    return state.allScholarshipBootcamp.filter(
        (scholarship) =>
            new Date(scholarship.application_period_start) <= today &&
            new Date(scholarship.application_period_end) >= today
    );
};

const getSingleScholarship = (state) => (scholarshipName) => {
    return state.allScholarshipBootcamp.find(
        (scholarship) => scholarship.bootcamp_name === scholarshipName
    );
};

const getProgramTracks = (state) => (routeId) => {
    return state.bootcampsAndPrograms.forEach((schoolInfo) => {
        if (routeId === schoolInfo.id) {
            state.schoolName = schoolInfo.bootcamp_name;
            state.programList = schoolInfo.program.map((i) => i.program_id);
        }
    });
};

const getProgramTrackList = (state) => {
    return state.allLessonsTrack.filter((i) => {
        return state.programList.some((el) => {
            return el == i.program_id;
        });
    });
};

const getBootcampDetails = (state) => (schoolName) => {
    return state.bootcampsAndPrograms.find(
        (bootcamp) => bootcamp.bootcamp_name === schoolName
    );
};

export default {
    bootcampsAndPrograms,
    getAllLessonsTrack,
    getSingleTrack,
    getDufunaTracks,
    getActiveScholarships,
    getSingleScholarship,
    getProgramTracks,
    getProgramTrackList,
    getBootcampDetails,
};
