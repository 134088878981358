import Repository from "./Repository";
import router from "@/router";

const resource = "registrations";
let config = {
    headers: {
        "Content-Type": "application/json",
    },
};

let getApplicantTopics = (registrationId) => {
    return Repository.get(`${resource}/${registrationId}/topics`);
};

const getApplicantTaskAndQuiz = (registrationId, topicId) => {
    return Repository.get(`${resource}/${registrationId}/topics/${topicId}`);
};

let saveApplicantTask = (taskId, taskUrl) => {
    let id = router.currentRoute.value.params.id;
    let body = { task_id: taskId, google_url: taskUrl };
    return Repository.post(`${resource}/${id}/google-drive-task`, body, config);
};

let getApplicantAccess = (registrationId, accessToken) => {
    const params = {
        access_token: accessToken,
    };
    return Repository.get(`${resource}/${registrationId}/access?`, {
        params: params,
    });
};

let saveTestSession = (regId, testId) => {
    return Repository.post(
        `quiz/registration/${regId}/tests/${testId}/sessions?`
    );
};

let getApplicantSelectionTest = (registrationId, accessToken) => {
    const params = {
        access_token: accessToken,
    };
    return Repository.get(`${resource}/${registrationId}/selection-tests?`, {
        params: params,
    });
};

let enrollApplicant = (regId, status) => {
    let body = {
        registration_id: regId,
        status: status,
    };
    return Repository.post(`${resource}/accept-decline`, body);
};

let freeScholarshipRegistration = (newScholarData, accessToken) => {
    let body = {
        action: "save-scholar-data",
        data: newScholarData,
    };
    let params = {
        access_token: accessToken,
    };
    return Repository.post(
        `${resource}/scholarship-registration`,
        body,
        { params },
        config
    );
};

let verifyScholarExist = (newScholarData, accessToken) => {
    let body = {
        action: "get-scholar-data",
        data: newScholarData,
    };
    let params = {
        access_token: accessToken,
    };
    return Repository.post(
        `${resource}/scholarship-registration`,
        body,
        { params },
        config
    );
};

let saveScholarPaidRegistration = (
    programId,
    firstName,
    lastName,
    email,
    phoneNumber,
    accessToken
) => {
    let body = {
        program_id: programId,
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber,
    };
    const params = {
        access_token: accessToken,
    };
    return Repository.post(
        `${resource}/paid-registration?`,
        body,
        { params },
        config
    );
};

export default {
    getApplicantTopics,
    getApplicantTaskAndQuiz,
    saveApplicantTask,
    getApplicantAccess,
    saveTestSession,
    getApplicantSelectionTest,
    enrollApplicant,
    freeScholarshipRegistration,
    verifyScholarExist,
    saveScholarPaidRegistration,
};
