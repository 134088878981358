export default {
    scholarsData: {
        first_name: "",
        last_name: "",
        selfie_url: "",
        github_username: null,
        pillItems: null,
    },
    applicantTopics: [],
    applicantPrograms: [],
    githubTokenGenerateState: "",
    applicantQuizzes: [],
    loadingStatus: false,
    isLoadingVerify: false,
    isLoadingScholarshipReg: false,
    isLoadingPaidReg: false,
    applicantAccessState: {},
    loadingNavigation: false,
    applicantEnrollStatus: {},
    freeScholarshipRegistrationState: {},
    verifyScholarExistState: {},
    saveScholarPaidRegistration: {},
    applicantDataByEmail: {},
    applicantTaskState: [],
    saveApplicantTaskState: {},
    applicantCertificates: [],
};
