import Repository from "./Repository";

const resource = "quiz";

let config = {
    headers: {
        "Content-Type": "application/json",
    },
};

let getTestQuestions = (testId) => {
    return Repository.get(`${resource}/${testId}/quiz-questions`);
};

let saveTestQuestions = (sessionId, answers, testScore) => {
    let body = {
        answers: answers,
        test_score: testScore
    }
    return Repository.post(`${resource}/${sessionId}/answers?`, body, config);
};

let getSelectionTestQuestions = (testId, accessToken) => {
    let params = {
        access_token: accessToken
    };
    return Repository.get(`${resource}/${testId}/questions?`, { params });
};

export default {
    getTestQuestions,
    saveTestQuestions,
    getSelectionTestQuestions
};
