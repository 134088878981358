import { RepositoryFactory } from "../../../repo/RepositoryFactory";
import { utils } from "../../../utils";
import router from "@/router";
const applicantRepository = RepositoryFactory.get("applicant");
const registrationRepository = RepositoryFactory.get("registration");
let responseCodes = utils.constants.responseCodes;

const accountInfo = async (context, Id) => {
    const userId = context.rootState.user.id;
    await applicantRepository
        .accountInfo(userId)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === 200) {
                context.commit("getScholarProfile", responseData.data);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, "getScholarProfile", Id);
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};
const getApplicantCertificates = async (context, applicantEmail) => {
    const userEmail = context.rootState.user.email;
    context.commit("loadingStatus", true);
    await applicantRepository
        .getApplicantCertificates(userEmail)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            console.log(responseData);
            if (
                responseStatus === responseCodes.success &&
                responseData.status === "success"
            ) {
                context.commit("getApplicantCertificates", responseData.data);
                context.commit("loadingStatus", false);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "applicantCertificates",
                    applicantEmail
                );
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const applicantImage = async (context, file) => {
    const userId = context.rootState.user.id;

    await applicantRepository
        .applicantImage(userId, file)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === 200) {
                context.commit("mutateScholarImage", responseData.data);
                alert("Profile picture updated successfully!")
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, "getScholarProfile");
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const getApplicantPrograms = async (context, applicantId) => {
    const userId = context.rootState.user.id;
    context.commit("loadingStatus", true);
    await applicantRepository
        .getApplicantPrograms(userId)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (
                responseStatus === responseCodes.success &&
                responseData.status === "success"
            ) {
                context.commit("setApplicantPrograms", responseData.data);
                context.commit("loadingStatus", false);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "setApplicantPrograms",
                    applicantId
                );
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const generateGithubAccessToken = async (context) => {
    let userAccessToken = context.rootState.home.token;
    await applicantRepository
        .generateGithubAccessToken(userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === 200) {
                if (responseData.length <= 50) {
                    context.commit("mutateGithubAccessToken", responseData);
                }
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "mutateGithubAccessToken"
                );
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const linkGithubAccount = async (context, body) => {
    let githubId = body.github_id;
    let githubUsername = body.github_username;
    let githubToken = body.github_token;
    let userAccessToken = context.rootState.home.token;
    const userId = context.rootState.user.id;
    await applicantRepository
        .linkGithubAccount(
            userId,
            githubId,
            githubUsername,
            githubToken,
            userAccessToken
        )
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === 200) {
                context.commit("mutateLinkGithubAccount", responseData);
            } else if (responseStatus === responseCodes.invalidToken) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "mutateLinkGithubAccount"
                );
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const getApplicantTopics = async (context, id) => {
    context.commit("loadingNavigation", true);
    await registrationRepository
        .getApplicantTopics(id)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (
                responseStatus === responseCodes.success &&
                responseData.status === "success"
            ) {
                // router.push({ path: `/track/intro/${id}` });
                context.commit("setApplicantTopics", responseData.data);
            } else if (responseStatus === responseCodes.invalidToken) {
                utils.misc.onAuthTokenExpired(context, "setApplicantTopics");
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const getApplicantTaskAndQuiz = async (context, payload) => {
    const { id, topic_id } = payload;
    try {
        const response = await registrationRepository.getApplicantTaskAndQuiz(
            id,
            topic_id
        );
        const responseData = response.data;
        const responseStatus = response.status;

        if (
            responseStatus === responseCodes.success &&
            responseData.status === "success"
        ) {
            context.commit("mutateApplicantTask", responseData.data);
        } else if (responseStatus === responseCodes.invalidToken) {
            utils.misc.onAuthTokenExpired(context, "mutateApplicantTask");
        } else if (!responseData.message) {
            utils.misc.showInternalError(context);
        } else {
            utils.misc.showGeneralError(context, responseData.message);
        }
    } catch (error) {
        utils.misc.catchNetworkError(context);
    }
};

const saveApplicantTask = async (context, body) => {
    let taskId = body.task_id;
    let taskUrl = body.google_url;
    await registrationRepository
        .saveApplicantTask(taskId, taskUrl)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === 200) {
                utils.misc.showSuccessMessage(
                    context,
                    `<br>` +
                        `<p class="m-0" style="color: green;">Task Submitted Successfully! </p>`
                );
                context.commit("mutateSaveApplicantTask", responseData);
            } else if (responseStatus === responseCodes.invalidToken) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "mutateSaveApplicantTask"
                );
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const getApplicantAccess = async (context, id) => {
    await registrationRepository
        .getApplicantAccess(id)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (
                responseStatus === responseCodes.success &&
                responseData.data.has_access === true
            ) {
                router.push({ path: `/track/intro/${id}` });
                context.commit("mutateApplicantAccess", responseData.data);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, "mutateApplicantTask");
            } else if (responseData.data.has_access === false) {
                utils.misc.showGeneralError(
                    context,
                    `<p class="m-0">${responseData.data.message}</p>` +
                        "<br/>" +
                        `<a href="${responseData.data.payment_link}"
                    class="bg-purple text-purple shadow rounded p-2 fw-bold"
                    target="_blank" role="button">Click here</a>`
                );
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const enrollApplicant = async (context, payload) => {
    let regId = payload.regId;
    let status = payload.status;

    const userAccessToken = context.rootState.home.token;
    await registrationRepository
        .enrollApplicant(regId, status, userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (
                responseStatus === responseCodes.success &&
                responseData.status === "success"
            ) {
                context.commit("setEnrollApplicant", responseData.data);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, "setEnrollApplicant");
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const freeScholarshipRegistration = async (context, body) => {
    context.commit("mutateIsLoadingScholarshipReg", true);
    const userAccessToken = context.rootState.home.token;
    await registrationRepository
        .freeScholarshipRegistration(body, userAccessToken)
        .then((response) => {
            const responseData = response.data;
            if (responseData.status === "success") {
                context.commit(
                    "mutateFreeScholarshipRegistration",
                    responseData.data
                );
                context.commit("mutateIsLoadingScholarshipReg", false);
                router.push(`/school-application-success`);
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
                context.commit("mutateIsLoadingScholarshipReg", false);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
                context.commit("mutateIsLoadingScholarshipReg", false);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const verifyScholarExist = async (context, body) => {
    context.commit("mutateIsLoadingVerify", true);
    const userAccessToken = context.rootState.home.token;
    await registrationRepository
        .verifyScholarExist(body, userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseData.status === "success") {
                context.commit("mutateVerifyScholarExist", responseData.data);
                context.commit("mutateIsLoadingVerify", false);
                router.push({ name: "PersonalInformation" });
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "mutateVerifyScholarExist"
                );
                context.commit("mutateIsLoadingVerify", false);
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
                context.commit("mutateIsLoadingVerify", false);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const saveScholarPaidRegistration = async (context, payload) => {
    context.commit("mutateIsLoadingPaidReg", true);
    let programId = payload.programId;
    let firstName = payload.firstName;
    let lastName = payload.lastName;
    let email = payload.email;
    let phoneNumber = payload.phoneNumber;
    let userAccessToken = context.rootState.home.token;
    await registrationRepository
        .saveScholarPaidRegistration(
            programId,
            firstName,
            lastName,
            email,
            phoneNumber,
            userAccessToken
        )
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseData.status === "success") {
                router.push({
                    name: "TrackApplicationSuccess",
                    query: { email: email },
                });
                context.commit(
                    "setSaveScholarPaidRegistration",
                    responseData.data
                );
                context.commit("mutateIsLoadingPaidReg", false);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "setSaveScholarPaidRegistration"
                );
                context.commit("mutateIsLoadingPaidReg", false);
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
                context.commit("mutateIsLoadingPaidReg", false);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
                context.commit("mutateIsLoadingPaidReg", false);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

const getApplicantDataByEmail = async (context, applicantEmail) => {
    let userAccessToken = context.rootState.home.token;
    await applicantRepository
        .getApplicantDataByEmail(userAccessToken, applicantEmail)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (
                (responseStatus === responseCodes.success &&
                    responseData.status === "success") ||
                responseStatus === 400
            ) {
                context.commit("setApplicantDataByEmail", responseData.data);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(
                    context,
                    "setApplicantDataByEmail",
                    applicantEmail
                );
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        })
        .catch(() => {
            utils.misc.catchNetworkError(context);
        });
};

export default {
    accountInfo,
    getApplicantCertificates,
    applicantImage,
    getApplicantPrograms,
    generateGithubAccessToken,
    linkGithubAccount,
    getApplicantTopics,
    getApplicantTaskAndQuiz,
    saveApplicantTask,
    getApplicantAccess,
    enrollApplicant,
    freeScholarshipRegistration,
    verifyScholarExist,
    saveScholarPaidRegistration,
    getApplicantDataByEmail,
};
