function catchNetworkError(context) {
    context.commit("updateError", {}, { root: true });
}

function showGeneralError(context, message) {
    showError(context, message, "An error has occurred");
}

function showSuccessMessage(context, message) {
    showSuccess(context, message);
}

function onAuthTokenExpired(context, action, payload) {
    context.dispatch(
        "home/getAuthToken",
        {
            action: action,
            isScope: false,
            param: payload,
        },
        { root: true }
    );
}

function showInternalError(context) {
    showGeneralError(
        context,
        "Oops, something went wrong. " +
        "There was an internal error, our engineers have been" +
        " notified and would resolve this shortly."
    );
}

function showError(context, message, title) {
    context.commit(
        "updateError",
        { title: title, message: message },
        { root: true }
    );
}

function showSuccess(context, message, title) {
    context.commit(
        "updateAlert",
        { title: title, message: message },
        { root: true }
    );
}

function showAlert(store, payload) {
    store.commit(
        "updateAlert",
        {
            title: payload.title,
            message: payload.message,
            buttons: payload.buttons,
        },
        { root: true }
    );
}

function showHideLoading(context) {
    context.commit("updateError", {}, { root: true });
}

function fancyTimeFormat(duration) {
    duration = Number(duration)
    let new_dur = Math.floor(duration / 1000);

    const hour = Math.floor(new_dur / 3600).toString().padStart(2, '0'),
        minute = Math.floor(new_dur % 3600 / 60).toString().padStart(2, '0'),
        second = Math.floor(new_dur % 60).toString().padStart(2, '0');

    if (hour > 0) {
        return hour + ':' + minute + ':' + second;
    } else {
        return minute + ':' + second;
    }
}

function formattedTime(duration) {
    duration = Number(duration)

    const minutes = Math.floor(duration / 60)
    const seconds = Math.floor(duration % 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

const pluralize = (count, word, suffix = 's') => `${count} ${word}${count !== 1 ? suffix : ''}`;

function validateEmail(email) {
	// eslint-disable-next-line no-useless-escape
	let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	return re.test(email);
}

function validateUrl(url) {
	let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

	return !!pattern.test(url);
}
export default {
    catchNetworkError,
    showGeneralError,
    showSuccessMessage,
    onAuthTokenExpired,
    showInternalError,
    showError,
    showSuccess,
    showAlert,
    showHideLoading,
    fancyTimeFormat,
    pluralize,
    validateEmail,
    validateUrl,
    formattedTime
};
