import { RepositoryFactory } from "../../../repo/RepositoryFactory";
import { utils } from "../../../utils";
const testRepository = RepositoryFactory.get("test");
const registrationRepository = RepositoryFactory.get("registration");
const selectionRepository = RepositoryFactory.get("selection");
let responseCodes = utils.constants.responseCodes;

const getTestQuestions = async (context, id) => {
    await testRepository.getTestQuestions(id)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === responseCodes.success && responseData.status === "success") {
                context.commit('setTestQuestions', responseData.data)
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, 'setTestQuestions');
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
};

const saveTestQuestions = async (context, requestBody) => {
    let sessionId = requestBody.sessionId
    let answers = requestBody.answers
    let testScore = requestBody.testScore

    let userAccessToken = context.rootState.home.token;
    await testRepository.saveTestQuestions(sessionId, answers, testScore, userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === responseCodes.success && responseData.status === "success") {
                context.commit('setSaveTestQuestions', responseData.data)
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, 'setSaveTestQuestions');
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
};

const saveTestSession = async (context, payload) => {
    let regId = payload.regId
    let quizId = payload.quizId
    const userAccessToken = context.rootState.home.token
    await registrationRepository.saveTestSession(regId, quizId, userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === responseCodes.success && responseData.status === "success") {
                context.commit('setTestSession', responseData.data)
                context.commit('loadingScore', false)
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, 'setTestSession');
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
};

const getSelectionTest = async (context, regId) => {
    let userAccessToken = context.rootState.home.token
    await registrationRepository.getApplicantSelectionTest(regId, userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === responseCodes.success && responseData.status === "success") {
                context.commit('setApplicantSelectionTest', responseData.data)
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, 'setApplicantSelectionTest');
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
};

const saveSelectionTestSession = async (context, payload) => {
    let regId = payload.regId
    let quizId = payload.quizId
    await selectionRepository.saveSelectionTestSession(quizId, regId)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === responseCodes.success || responseData.status === "success") {
                context.commit('setSelectionTestSession', responseData.data)
                context.commit('loadingScore', false)
            } else if (responseStatus === responseCodes.invalidToken) {
                utils.misc.onAuthTokenExpired(context, 'setSelectionTestSession');
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
};

export default {
    getTestQuestions,
    saveTestQuestions,
    saveTestSession,
    getSelectionTest,
    saveSelectionTestSession
};
