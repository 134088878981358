const userId = (state) => {
    return state.user.id;
};
const userEmail = (state) => {
    return state.user.email;
};

export default {
    userId,
    userEmail,
};
