const testQuestions = (state) => {
    return state.testQuestions;
}

const testSession = (state) => {
    return state.testSession;
}

const loadingScore = (state) => {
    return state.loadingNavigation
}

const selectionTest = (state) => {
    return state.selectionTest;
}

const selectionTestSession = (state) => {
    return state.selectionTestSession;
}

export default {
    testQuestions,
    testSession,
    loadingScore,
    selectionTest,
    selectionTestSession
}
