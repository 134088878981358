<template>
    <section class="landing-page">
        <div v-if="shown" class="container py-1 fw-bold">
            <div class="row p-2 align-items-center">
                <div class="col col-12 col-md-6">
                    Hurray!! &#127882; The Dufuna Learning PWA is now available for installation!
                </div>

                <div class="col col-12 col-md-6">
                    <div class="d-flex gap-3 align-items-center">
                        <button type="button" @click="installPWA" class="btn btn-brand-primary px-3 py-1 text-capitalize">
                            Install
                        </button>
                        <button type="button" @click="dismissPrompt"
                            class="btn btn-outline-secondary text-dark py-1 text-capitalize"> Not Now </button>
                    </div>
                </div>
            </div>
        </div>

        <page-header :isMainHeader="true" />
        <section class="landing-page__main">
            <hero-component />
            <educational-partners />
            <available-tracks />
            <students-testimonials />
            <certification-section />
            <alumni-community />
            <page-footer />
        </section>
    </section>
</template>

<script>
import PageHeader from '../elements/PageHeader.vue'
import PageFooter from '../elements/PageFooter.vue'
import EducationalPartners from '../website-pages/EducationalPartners.vue'
import AlumniCommunity from './AlumniCommunity.vue'
import AvailableTracks from './AvailableTracks.vue'
import CertificationSection from './CertificationSection.vue'
import HeroComponent from './HeroComponent.vue'
import StudentsTestimonials from './StudentsTestimonials.vue'

export default {
    components: { PageHeader, PageFooter, EducationalPartners, AvailableTracks, HeroComponent, StudentsTestimonials, CertificationSection, AlumniCommunity },
    name: "LandingPage",
    data() {
        return {
            shown: false,
        }
    },
    beforeMount() {
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent the default behavior of showing the install banner
            e.preventDefault()

            // Track PWA installation event in Google Analytics
            this.$gtag.event('install', 'prompt_shown');

            // Store the event for later use when the user interacts with it
            this.installEvent = e
            this.shown = true
        })

        localStorage.removeItem("scholarshipRegistrationDetails");
    },
    methods: {
        dismissPrompt() {
            this.shown = false
        },
        installPWA() {
            this.installEvent.prompt()
            this.installEvent.userChoice.then((choice) => {
                this.dismissPrompt() // Hide the prompt once the user's clicked
                if (choice.outcome === 'accepted') {
                    // Do something additional if the user chose to install
                    this.$gtag.event('install', 'initiated');
                } else {
                    // Do something additional if the user declined
                }
            })
        },
    }
}
</script>

<style lang="scss">
@import "@/assets/scss/pages/landing-page.scss";
</style>
