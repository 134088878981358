<template>
    <section class="hero">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-lg-3">
                    <p class="hero__heading">
                        Kickstart your <span v-if="!hasPartnerColor" class="text-blue-gradient">career</span> <span
                            v-if="hasPartnerColor" :style="{ color: partnerColor }">career</span> in tech today.
                    </p>
                    <p class="hero__sub-text">Learn in-demand tech skills, work on practical projects and build your
                        portfolio.
                    </p>
                    <p v-if="!hasPartnerColor" class="pb-3"><img src="@/assets/img/wave.svg" alt="wavy vector"></p>
                    <router-link v-if="hasPartnerColor" role="button" to="/"
                        class="btn custom-spacing justify-content-center"
                        :style="{ backgroundColor: partnerColor, color: partnerTextColor }">
                        Get Started <span class="px-1"><img src="@/assets/img/arrow-up-right.svg"
                                alt="arrow pointing right"></span>
                    </router-link>
                    <router-link v-if="!hasPartnerColor" role="button" :to="{ name: 'TrackApplicationPage' }"
                        class="btn btn-brand-primary custom-spacing justify-content-center">
                        Get Started <span class="px-1"><img src="@/assets/img/arrow-up-right.svg"
                                alt="arrow pointing right"></span>
                    </router-link>
                </div>
            </div>
            <div v-if="hasPartnerColor" class="mt-4 d-flex text-medium-gray">
                <p class="mt-auto">In partnership with</p>
                <img id="partner-logo" :src="partnerLogoUrl" alt="partner logo">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "HeroComponent",
    props: {
        partnerColor: String,
        partnerLogoUrl: String,
    },
    computed: {
        hasPartnerColor() {
            return Boolean(this.partnerColor);
        },
        partnerTextColor() {
            const threshold = 186;
            const red = parseInt(this.partnerColor.slice(1, 3), 16);
            const green = parseInt(this.partnerColor.slice(3, 5), 16);
            const blue = parseInt(this.partnerColor.slice(5, 7), 16);
            const contrastRatio = ((red * .299) + (green * .587) + (blue * .114));
            const contrastColor = (contrastRatio >= threshold) ? '#000000' : '#ffffff';
            return contrastColor;
        }
    },
}
</script>

<style>
#partner-logo {
    height: 50px;
    width: auto;
    object-fit: cover;
    margin-left: 1rem;
}
</style>