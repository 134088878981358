import AuthRepository from "./AuthRepository";
import AccountRepository from "./AccountRepository";
import ApplicantRepository from "./ApplicantRepository";
import RegistrationRepository from "./RegistrationRepository";
import lessonsRepository from "./LessonsRepository";
import logsRepository from "./logsRepository";
import TestRepository from "./TestRepository";
import SelectionRepository from "./SelectionRepository";
import DataRepository from "./DataRepository"

const repositories = {
	auth: AuthRepository,
	account: AccountRepository,
	applicant: ApplicantRepository,
	registration: RegistrationRepository,
	lessons: lessonsRepository,
	logs: logsRepository,
	test: TestRepository,
	selection: SelectionRepository,
	data: DataRepository
};

export const RepositoryFactory = {
    get: name => repositories[name]
};