const setTestQuestions = (state, testQuestions) => {
    state.testQuestions = testQuestions
};

const setSaveTestQuestions = (state, payload) => {
    state.saveTestQuestionState.push(payload)
};

const setTestSession = (state, payload) => {
    state.testSession = payload
};

const loadingScore = (state, newLoadingNavigation) => {
    state.loadingNavigation = newLoadingNavigation
};

const setApplicantSelectionTest = (state, selectionTest) => {
    state.selectionTest = selectionTest
};

const setSelectionTestSession = (state, payload) => {
    state.selectionTestSession = payload
};

export default {
    setTestQuestions,
    setSaveTestQuestions,
    setTestSession,
    loadingScore,
    setApplicantSelectionTest,
    setSelectionTestSession
};
