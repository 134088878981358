import {RepositoryFactory} from "../repo/RepositoryFactory";
import {utils} from "../utils";

const accountRepository = RepositoryFactory.get('data');

const getData = async (context) => {
    utils.misc.showHideLoading(context, true);
    context.commit('setData', null);
    await accountRepository.getData(context.rootState.home.newBackendToken)
        .then((response) => {
            let responseData = response.data;
            let responseCodes = utils.constants.responseCodes;
            if (response.status === responseCodes.success && responseData.status === "success") {
                context.commit('setData', responseData.data);
            } else if (response.status === responseCodes.invalidToken) {
                utils.misc.onAuthTokenExpired(context,
                    "getData",
                    null);
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
            utils.misc.showHideLoading(context, false);
        }).catch(() => {
            utils.misc.catchNetworkError(context);
            utils.misc.showHideLoading(context, false);
        })
};

const resetAllStates = (context) => {
    context.commit("auth/resetAuthStates");
    context.commit("applicant/resetApplicantStates");
};

export default {
    getData,
    resetAllStates
};
