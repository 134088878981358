const getToken = (state) => {
    return state.token;
};

const getNewBackendToken = (state) => {
    return state.newBackendToken;
};

export default {
    getToken,
    getNewBackendToken,
};
