import Repository from "./Repository";
const resource = "logs";

let config = {
    headers: {
        "Content-Type": "application/json",
    },
};

let logLessonsVideo = (actionStatus, registrationId, videoId, accessToken) => {

    let params = {
        access_token: accessToken
    };

    let body = {
        action: actionStatus,
        data: {
            registration_id: registrationId,
            video_id: videoId
        }
    };

    return Repository.post(`${resource}/lesson-video-logs`, body, { params }, config);
};


let logLessonDocs = (registrationId, lessonId) => {
    let body = {
        registration_id: registrationId,
        lesson_id: lessonId
    };
    return Repository.post(`${resource}/lesson-doc-logs`, body, config);
};


export default {
    logLessonsVideo,
    logLessonDocs
};
