import { RepositoryFactory } from "../../../repo/RepositoryFactory";
import { utils } from "../../../utils";
const selectionRepository = RepositoryFactory.get("selection");
let responseCodes = utils.constants.responseCodes;

const getSelectionTestQuestions = async (context, id) => {
    let userAccessToken = context.rootState.home.token
    await selectionRepository.getSelectionTestQuestions(id, userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === responseCodes.success && responseData.status === "success") {
                context.commit('setSelectionTestQuestions', responseData.data)
            } else if (responseStatus === responseCodes.invalidToken) {
                utils.misc.onAuthTokenExpired(context, 'setSelectionTestQuestions');
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
};

const saveSelectionTestQuestions = async (context, requestBody) => {
    let sessionId = requestBody.sessionId
    let answers = requestBody.answers
    let testScore = requestBody.testScore

    let userAccessToken = context.rootState.home.token;
    await selectionRepository.saveTestQuestions(sessionId, answers, testScore, userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === responseCodes.success || responseData.status === "success") {
                context.commit('setSaveSelectionTestQuestions', responseData.data)
            } else if (responseStatus === responseCodes.invalidToken) {
                utils.misc.onAuthTokenExpired(context, 'setSaveSelectionTestQuestions');
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
};

const saveSelectionTestSession = async (context, payload) => {
    let regId = payload.regId
    let quizId = payload.quizId
    const userAccessToken = context.rootState.home.token
    await selectionRepository.saveSelectionTestSession(quizId, regId, userAccessToken)
        .then((response) => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === responseCodes.success || responseData.status === "success") {
                context.commit('setSelectionTestSession', responseData.data)
                context.commit('loadingScore', false)
            } else if (responseStatus === responseCodes.invalidToken) {
                utils.misc.onAuthTokenExpired(context, 'setSelectionTestSession');
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
};

export default {
    getSelectionTestQuestions,
    saveSelectionTestQuestions,
    saveSelectionTestSession
};
