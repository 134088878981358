import index from "./index";

const updateToken = (state, payload) => {
    state.token = payload.tokenData;
};

const updateNewBackendToken = (state, payload) => {
    state.newBackendToken = payload.tokenData;
};

const resetHomeStates = (state) => {
    Object.assign(state, index.getDefaultHomeStates());
};

export default {
    updateToken,
    updateNewBackendToken,
    resetHomeStates,
};
