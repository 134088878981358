import Repository from "./Repository";
import router from "@/router";

const resource = "applicant";
let config = {
    headers: {
        "Content-Type": "application/json",
    },
};

let accountInfo = (id) => {
    return Repository.get(`${resource}/${id}/profile`, config);
};
let getApplicantCertificates = (applicantEmail) => {
    return Repository.get(`${resource}/${applicantEmail}/certificate`, config);
};

const applicantImage = (id, file) => {
    const url = `${resource}/${id}/upload-selfie`;
    let formData = new FormData();
    formData.append("image", file, file.name);
    return Repository.post(url, formData, {
        headers: {
            "Content-Type": file.type, // Use the MIME type of the uploaded file
        },
    });
};

const getApplicantPrograms = (applicantId) => {
    return Repository.get(`${resource}/${applicantId}/programs`);
};

let generateGithubAccessToken = (accessToken) => {
    let params = {
        access_token: accessToken,
    };
    let code = router.currentRoute.query.code;
    return Repository.post(
        `${resource}/${code}/githubtoken`,
        {},
        { params },
        config
    );
};

let linkGithubAccount = (
    id,
    githubId,
    githubUsername,
    githubToken,
    accessToken
) => {
    let params = {
        access_token: accessToken,
    };
    let body = {
        github_id: githubId,
        github_username: githubUsername,
        github_token: githubToken,
    };
    return Repository.put(
        `${resource}/${id}/githubid`,
        body,
        { params },
        config
    );
};

const getApplicantDataByEmail = (accessToken, applicantEmail) => {
    const params = {
        access_token: accessToken,
    };
    return Repository.get(`${resource}/${applicantEmail}/scholar-profile?`, {
        params: params,
    });
};

export default {
    accountInfo,
    getApplicantCertificates,
    applicantImage,
    generateGithubAccessToken,
    linkGithubAccount,
    getApplicantPrograms,
    getApplicantDataByEmail,
};
