<template>
    <section class="container py-5">
        <div class="row align-items-start">
            <div class="col-12 col-md-3 text-medium-gray">
                OUR EDUCATIONAL <br> PARTNERS
            </div>
            <div class="col-12 col-md-9">
                <div class="row partners-logos">
                    <div v-for="(partner, index) in eduPartners" :key="index" class="col-4 col-md-3 d-flex justify-content-start">
                        <img :src="partner.image" :alt="partner.name" class="img-fluid partners-image" loading="lazy" />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "EducationalPartners",
    data: () => ({
        eduPartners: [
            { image: require("@/assets/img/brand/nationalFinancial.svg"), name: 'National Financial' },
            { image: require("@/assets/img/brand/ukNigeriaTechHub.svg"), name: 'Uk Nigeria Tech Hub' },
            { image: require("@/assets/img/brand/paradigmIni.svg"), name: 'Paradigm Initiative' },
            { image: require("@/assets/img/brand/bongoHive.svg"), name: 'Bongo Hive' },
            { image: require("@/assets/img/brand/mzuzu.svg"), name: 'Mzuzu Enterpreneur Hub' },
            { image: require("@/assets/img/brand/makerere.svg"), name: 'Makerere Innovation And Incubation Center' },
            { image: require("@/assets/img/brand/ahk.svg"), name: 'AHK' },
            { image: require("@/assets/img/brand/swahilipotHub.svg"), name: 'Swahilipot Hub' },
            { image: require("@/assets/img/brand/catalystFund.svg"), name: 'Catalyst Fund' }
        ]
    })
}
</script>