import { RepositoryFactory } from "../../../repo/RepositoryFactory";
import { utils } from "../../../utils";

const authRepository = RepositoryFactory.get("auth");
// let responseCodes = utils.constants.responseCodes;

const getAuthToken = async (context, payload) => {
    try {
        // const existingBackendResponse =
        //     await authRepository.getAccessTokenFromExistingBackend();
        // const existingBackendData = existingBackendResponse.data;

        // if (
        //     existingBackendResponse.status === responseCodes.success &&
        //     existingBackendData.status === "success"
        // ) {
        //     let existingBackendToken = existingBackendData.data.access_token;
        //     context.commit("updateToken", { tokenData: existingBackendToken });
        //     context.commit("updateError", { title: "", message: "" }, { root: true });
        // } else {
        //     utils.misc.showGeneralError(
        //         context,
        //         existingBackendData.message ||
        //         "Error getting token from existing backend"
        //     );
        //     utils.misc.showHideLoading(context, false);
        //     return;
        // }
        // for Babz and Michael
        // This where I'm getting the token from the new backend setup and storing it in the store
        const newBackendResponse =
            await authRepository.getAccessTokenFromNewBackend();
        const newBackendData = newBackendResponse.data;
        if (
            newBackendData.status === "success" &&
            newBackendData.token
        ) {
            let newBackendToken = newBackendData.token;
            context.commit("updateNewBackendToken", { tokenData: newBackendToken });
            context.commit("updateError", { title: "", message: "" }, { root: true });
        } else {
            utils.misc.showGeneralError(
                context,
                newBackendData.message || "Error getting token from new backend"
            );
            utils.misc.showHideLoading(context, false);
            return;
        }

        if (payload.isScope) {
            context.dispatch(payload.action);
        } else {
            context.dispatch(payload.action, payload.param, { root: true });
        }

        utils.misc.showHideLoading(context, false);
    } catch (err) {
        utils.misc.catchNetworkError(context);
        utils.misc.showHideLoading(context, false);
    }
};

export default {
    getAuthToken,
};
