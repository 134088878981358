import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import baseState from "./state";
import applicantModule from "./modules/applicantModule";
import authModule from "./modules/authModule";
import homeModule from "./modules/homeModule";
import lessonsModule from "./modules/lessonsModule";
import logsModule from "./modules/logsModule";
import testModule from "./modules/testModule";
import dataModule from "./modules/dataModule";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import selectionModule from "./modules/selectionModule";

const state = baseState.getDefaultState();

const store = createStore({
    plugins: [createPersistedState()],
    modules: {
        auth: authModule,
        home: homeModule,
        applicant: applicantModule,
        lessons: lessonsModule,
        logs: logsModule,
        test: testModule,
        selection: selectionModule,
        data: dataModule,
    },
    state,
    mutations,
    actions,
    getters,
});

export default store;
