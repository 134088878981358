const setBootcampsAndPrograms = (state, bootcampsAndPrograms) => {
    state.bootcampsAndPrograms = bootcampsAndPrograms
}

const allLessonsTrackMutation = (state, payload) => {
    state.allLessonsTrack = payload;
};

const allScholarshipBootcampMutation = (state, payload) => {
    state.allScholarshipBootcamp = payload;
};

const getCountriesAndStateMutation = (state, payload) => {
    state.countriesAndState = payload;
};

export default {
    setBootcampsAndPrograms,
    allLessonsTrackMutation,
    allScholarshipBootcampMutation,
    getCountriesAndStateMutation,
};