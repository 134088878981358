function getDefaultState() {
    return {
        error: {
            title: "",
            message: "",
        },
        alert: null,
        customer: null,
        user: {
            id: null,
            profile_id: null
        },
    };
}

export default {
    getDefaultState,
};
