import Repository from "./Repository";

const resource = "selection-tests";
let config = {
    headers: {
        "Content-Type": "application/json",
    },
};

let saveTestQuestions = (sessionId, answers, testScore) => {
    let body = {
        answers: answers,
        test_score: testScore
    }
    return Repository.post(`${resource}/${sessionId}/answers?`, body, config);
};

let saveSelectionTestSession = (regId, testId) => {
    return Repository.post(`${resource}/${regId}/registrations/${testId}`, {});
}

export default {
    saveTestQuestions,
    saveSelectionTestSession
};
