import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const getDefaultHomeStates = () => {
    return {
        token:"",
        newBackendToken:"",
    };
};

const state = getDefaultHomeStates();

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
