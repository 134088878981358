import Repository from "./Repository";

const getAccessToken = async (config) => {
    const requestConfig = {
        headers: {
            ...(config.additionalParams?.headers || {}),
        },
    };

    const body = {
        ...(config.grantType && { grant_type: config.grantType }),
        ...(config.clientId && { client_id: config.clientId }),
        ...(config.clientSecret && { client_secret: config.clientSecret }),
        ...(config.additionalParams.body && { email: config.additionalParams.body.email }),
        ...(config.additionalParams.body && { password: config.additionalParams.body.password }),
    };

    return Repository.post(
        `${config.baseURL}/${config.resource}`,
        body,
        requestConfig
    );
};


export default {

    // getAccessTokenFromExistingBackend() {
    //     const existingBackendConfig = {
    //         baseURL: process.env.VUE_APP_API_URL,
    //         resource: "oauth/token",
    //         clientId: process.env.VUE_APP_CLIENT_ID,
    //         clientSecret: process.env.VUE_APP_CLIENT_SECRET,
    //         grantType: "client_credentials",
    //         additionalParams: {
    //             'content-type': 'application/x-www-form-urlencoded',
    //             'access-control-allow-origin': '*',
    //         },
    //     };
    //     return getAccessToken(existingBackendConfig);
    // },

    // for Babz and Michael, this function is used to get the token from the new backend setup
    // Please refactor when the body is no longer needed to get the token

    getAccessTokenFromNewBackend() {
        const newBackendConfig = {
            baseURL: process.env.VUE_APP_NEW_API_URL,
            resource: "oauth/login",
            additionalParams: {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json"
                },
                body: {
                    email: process.env.VUE_APP_ACCESS_TOKEN_EMAIL,
                    password: process.env.VUE_APP_ACCESS_TOKEN_PASSWORD,
                },
            },
        };
        return getAccessToken(newBackendConfig);
    },
};
