const getScholarsData = (state) => {
    return state.scholarsData;
};
const applicantCertificates = (state) => {
    return state.applicantCertificates;
};

const getScholarImage = (state) => {
    return state.scholarsData.selfie_url;
};

const applicantPrograms = (state) => {
    return state.applicantPrograms;
};

const loadingStatus = (state) => {
    return state.loadingStatus;
};

const loadingNavigation = (state) => {
    return state.loadingNavigation;
};

const getApplicantTask = (state) => {
    return state.applicantTaskState.task;
};

const getGithubTokenGenerate = (state) => {
    return state.githubTokenGenerateState;
};

const applicantQuizzes = (state) => {
    return state.applicantTaskState.quiz;
};

const getfilteredQuizzes = (state, value) => {
    return state.applicantTaskState.quiz.filter((quiz) => quiz.topic == value);
};

const applicantTopics = (state) => {
    return state.applicantTopics;
};

const getApplicantAccessState = (state) => {
    return state.applicantAccessState;
};

const getApplicantDataByEmail = (state) => {
    return state.applicantDataByEmail;
};

export default {
    getScholarsData,
    getScholarImage,
    getApplicantTask,
    applicantPrograms,
    getGithubTokenGenerate,
    applicantQuizzes,
    getfilteredQuizzes,
    applicantTopics,
    loadingStatus,
    loadingNavigation,
    getApplicantAccessState,
    getApplicantDataByEmail,
    applicantCertificates,
};
