import { RepositoryFactory } from "../../../repo/RepositoryFactory";
import { utils } from "../../../utils";

const logRepository = RepositoryFactory.get("logs");
let responseCodes = utils.constants.responseCodes;

const logLessonsVideo = async (context, body) => {
    let registrationId = body.registration_id;
    let videoId = body.video_id;
    let actionStatus = body.action;
    let accessToken = context.rootState.home.token;
    await logRepository.logLessonsVideo(actionStatus, registrationId, videoId, accessToken)
        .then(response => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === 200) {
                context.commit('mutateLogLessons', responseData);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, 'mutateLogLessons');
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
}

const logLessonDocs = async (context, data) => {
    let registrationId = data.registration_id;
    let lessonId = data.lesson_id;
    let accessToken = context.rootState.home.token;
    await logRepository.logLessonDocs(registrationId, lessonId, accessToken)
        .then(response => {
            const responseData = response.data;
            const responseStatus = response.status;
            if (responseStatus === 200) {
                context.commit('mutatelogLessonDocs', responseData);
            } else if (responseStatus === responseCodes.invalidTokenCode) {
                utils.misc.onAuthTokenExpired(context, 'mutatelogLessonDocs');
            } else if (!responseData.message) {
                utils.misc.showInternalError(context);
            } else {
                utils.misc.showGeneralError(context, responseData.message);
            }
        }).catch(() => {
            utils.misc.catchNetworkError(context);
        })
}

export default {
    logLessonsVideo,
    logLessonDocs
};