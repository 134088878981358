const mutateLogLessons = (state, payload) => {
    state.scholarsLogLessons = payload;
}

const mutatelogLessonDocs = (state, payload) => {
    state.LessonDocsEvent = payload;
}

export default {
    mutateLogLessons,
    mutatelogLessonDocs
};