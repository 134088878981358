<template>
    <nav class="navbar navbar-expand-lg bg-light-purple pageheader">
        <div class="container">
            <a class="navbar-brand" href="/">
                <img
                    src="@/assets/img/brand/dufuna-learning.svg"
                    alt="dufuna learning logo"
                    class="img-fluid d-inline-block align-text-top"
                />
            </a>

            <span
                v-if="isLoggedIn"
                class="navbar-toggler"
                data-bs-target="#navbarSupportedContent"
                data-bs-toggle="collapse"
                type="button"
            >
                <i class="bi bi-list fs-2 text-black" />
            </span>

            <div
                v-if="isLoggedIn"
                class="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
            >
                <ul class="navbar-nav ml-lg-auto pageheader-links">
                    <li
                        v-for="(dashboardLink, index) in dashboardLinks"
                        :key="index"
                        class="nav-item"
                    >
                        <router-link
                            class="nav-link text-black"
                            :to="dashboardLink.href"
                            >{{ dashboardLink.input }}
                        </router-link>
                    </li>
                </ul>
            </div>

            <div
                v-if="isLoggedIn"
                class="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
            >
                <ul class="nav mb-2 mb-lg-0 d-flex align-items-center">
                    <!-- The line of code below is commented out temporarily -->
                    <!-- <li class="pageheader-icon pe-3"><a href="#"><i class="bi bi-bell"></i></a></li> -->
                    <li v-if="image == ''" class="pageheader-avatar">
                        <router-link to="/account/account-info">
                            <avatar
                                :fullname="fullName"
                                :size="40"
                                color="gray"
                            ></avatar>
                        </router-link>
                    </li>
                    <li v-else class="pageheader-avatar">
                        <router-link to="/account/account-info">
                            <avatar :image="image" :size="40" @change="updateDisplayImage"></avatar>
                        </router-link>
                    </li>
                </ul>
            </div>

            <span
                v-if="isMainHeader"
                class="navbar-toggler"
                data-bs-target="#navbarSupportedContent"
                data-bs-toggle="collapse"
                type="button"
            >
                <i class="bi bi-list fs-2 text-black" />
            </span>

            <div
                v-if="isMainHeader"
                class="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
            >
                <ul class="navbar-nav ml-lg-auto pageheader-links">
                    <li class="nav-item dropdown">
                        <router-link
                            class="nav-link text-black"
                            to="/"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            Schools <i class="bi bi-chevron-down"></i>
                        </router-link>
                        <div
                            class="dropdown-menu school-panel bg-patterned p-0"
                        >
                            <school-header-modal></school-header-modal>
                        </div>
                    </li>
                    <li
                        v-for="(link, index) in mainHeaderLinks"
                        :key="index"
                        class="nav-item"
                    >
                        <router-link class="nav-link text-black" :to="link.href"
                            >{{ link.input }} <br />
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link text-black"
                            :href="talentHref"
                            target="_blank"
                            >Talents</a
                        >
                    </li>
                </ul>
            </div>

            <div
                v-if="isMainHeader"
                class="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
            >
                <ul
                    class="nav mb-2 mb-lg-0 d-flex align-items-center gap-4 py-2"
                >
                    <li class="fw-bold">
                        <router-link to="/login"> SIGN IN </router-link>
                    </li>
                    <li v-if="hasPartnerColor">
                        <router-link
                            role="button"
                            to="#"
                            class="btn custom-spacing justify-content-center"
                            :style="{
                                backgroundColor: partnerColor,
                                color: partnerTextColor,
                            }"
                        >
                            Get Started
                        </router-link>
                    </li>
                    <li v-else>
                        <router-link
                            role="button"
                            :to="{ name: 'TrackApplicationPage' }"
                            class="btn btn-brand-primary custom-spacing justify-content-center"
                        >
                            Get Started
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import Avatar from "vue-avatar-component";
import { mapActions, mapState, mapGetters } from "vuex";
import SchoolHeaderModal from "../modals/SchoolHeaderModal.vue";

export default {
    name: "PageHeader",
    props: {
        isLoggedIn: { type: Boolean },
        isMainHeader: { type: Boolean },
        partnerColor: String,
    },
    components: { Avatar, SchoolHeaderModal },
    data() {
        return {
            fullName: "",
            image: "",
            dashboardLinks: [
                { input: "Courses", href: "/courses" },
                { input: "Certificates", href: "/certificates" },
            ],
            mainHeaderLinks: [
                { input: "Tracks", href: "/all-tracks" },
                { input: "FAQs", href: "/faq" },
                { input: "Scholarships", href: "/scholarships" },
                { input: "Blog", href: "/blog" },
            ],
            talentHref: process.env.VUE_APP_TALENT_URL,
        };
    },
    methods: {
        ...mapActions({ accountInfo: "applicant/accountInfo" }),

        updateDisplayImage(imageDataUrl) {
            this.image = imageDataUrl;
        },

        loadPendoScript(callback) {
            // Check if Pendo script already exists
            if (!window.pendo) {
                // Create script element
                const apiKey = process.env.VUE_APP_PENDO_API_KEY;
                const script = document.createElement("script");
                script.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
                script.async = true;
                // Script onload event
                script.onload = callback;
                // Append script to head
                document.head.appendChild(script);
            } else {
                // Pendo script already exists, execute callback
                callback();
            }
        },
    },
    computed: {
        ...mapState("applicant", { scholarsData: "scholarsData" }),
        ...mapGetters("applicant", { scholarsData: "getScholarsData" }),
        hasPartnerColor() {
            return Boolean(this.partnerColor);
        },
        partnerTextColor() {
            const threshold = 186;
            const red = parseInt(this.partnerColor.slice(1, 3), 16);
            const green = parseInt(this.partnerColor.slice(3, 5), 16);
            const blue = parseInt(this.partnerColor.slice(5, 7), 16);
            const contrastRatio = red * 0.299 + green * 0.587 + blue * 0.114;
            const contrastColor =
                contrastRatio >= threshold ? "#000000" : "#ffffff";
            return contrastColor;
        },
    },
    async mounted() {
        if (this.isLoggedIn) {
            let scholarLocalStorageData = localStorage.getItem("vuex");
            let parseData = JSON.parse(scholarLocalStorageData);
            let scholarEmail = parseData["user"].email;
            let scholarId = parseData["user"].id;
            let firstName = parseData["user"].first_name;
            let lastName = parseData["user"].last_name;
            this.image = localStorage.getItem("imagePreviewStyle");
            this.fullName = `${firstName}  ${lastName}`;

            // Dynamically load Pendo script
            this.loadPendoScript(() => {
                // Pendo script loaded, initialize Pendo
                window.pendo.initialize({
                    visitor: {
                        id: scholarId,
                        email: scholarEmail,
                        full_name: this.fullName,
                    },
                    account: {
                        id: this.scholarId,
                    },
                });
            });
        }

        if (this.hasPartnerColor) {
            this.mainHeaderLinks = [{ input: "Tracks", href: "/all-tracks" }];
        }
    },
};
</script>

<style>
.purple-bg {
    background: linear-gradient(
        90deg,
        rgba(0, 102, 245, 0.05) -37.5%,
        rgba(111, 48, 246, 0.05) 100%
    );
}
</style>
