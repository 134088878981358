<template>
    <section class="testimonials">
        <div class="container">
            <div class="row">
                <div class="mx-auto text-center">
                    <div class="row justify-content-center">
                        <p class="display__title pb-5">
                            <span>Don’t take our word for it.</span><br>
                            <span class="text-blue-gradient">Trust our students</span>
                        </p>
                    </div>
                </div>

                <div class="container text-center">
                    <div class="row">
                        <div class="col-12 col-lg-5 order-1 order-lg-0">
                            <ul class="d-flex list-group border border-0 mb-4">
                                <li v-for="(student, index) in students" :key="index"
                                    class="list-group-item border border-0 p-0 mb-3" role="presentation"
                                    @click="getScholarIndex(index)">
                                    <router-link class="btn btn-testimonials w-100 nav-link" to="#">
                                        <div class="info-block">
                                            <img :src="student.img" :alt="student.name + `'s Image`" class="img-avatar">
                                            <div class="row justify-content-center flex-column text-start">
                                                <span class="student-name">{{ student.name }}</span>
                                                <span class="student-role">{{ student.role }}</span>
                                            </div>
                                        </div>
                                    </router-link>
                                </li>
                            </ul>
                            <div class="d-grid col-6 col-lg-12 mx-auto">
                                <a href="https://www.youtube.com/@dufuna" target="_blank"
                                    class="btn btn-brand-primary justify-content-center w-100 py-3">View
                                    More</a>
                            </div>
                        </div>
                        <div class="col-12 col-lg-7 order-0  order-lg-1 mb-3">
                            <div class="videoWrapper" v-for="(student, index) in students" :key="index">
                                <iframe v-if="selectedScholar == index" :title="student.name" :src="student.link"
                                    frameborder="1" allowfullscreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "StudentsTestimonials",
    data() {
        return {
            selectedScholar: 0,
            students: [
                { link: "https://www.youtube.com/embed/ulldE9Hs80s", name: "Ireti Ogedengbe", role: "Software Engineer, Cotta & Cush Ltd", img: require("@/assets/img/ireti-ogedengbe.jpg") },
                { link: "https://www.youtube.com/embed/mkft33flhD0", name: "Kolawole Ogunfowokan", role: "Software Engineer, Konga", img: require("@/assets/img/kolawole-john.jpg") },
                { link: "https://www.youtube.com/embed/G7yi7oCiCos", name: "Philip Jemikalajah Okeoghene", role: "Software Engineer, Konga", img: require("@/assets/img/philip-okeoghene.jpeg") }
            ]
        }
    },
    methods: {
        getScholarIndex(event) {
            this.selectedScholar = event
            console.log(this.selectedScholar)
        },
    }
}
</script>