<template>
    <section class="bg-light-purple py-5 certification">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4 pb-3">
                    <p class="hero__heading certification__header-text">Get the <span v-if="!partnerColor"
                            class="text-blue-gradient">recognition</span> <span v-if="partnerColor"
                            :style="{ color: partnerColor }">recognition</span> you deserve</p>
                    <router-link v-if="!partnerColor" role="button" :to="{ name: 'TrackApplicationPage' }"
                        class="btn btn-brand-primary custom-spacing justify-content-center">
                        Start Learning <span class="px-1"><img src="@/assets/img/arrow-up-right.svg"
                                alt="arrow pointing right"></span>
                    </router-link>
                    <router-link v-if="partnerColor" role="button" to="/partners"
                        class="btn custom-spacing justify-content-center"
                        :style="{ backgroundColor: partnerColor, color: partnerTextColor }">
                        Start Learning <span class="px-1"><img src="@/assets/img/arrow-up-right.svg"
                                alt="arrow pointing right"></span>
                    </router-link>
                </div>
                <div class="col-12 col-md-8 d-flex justify-content-center flex-column align-items-center">
                    <img src="@/assets/img/certificate.png" width="539px" height="379px" class="img-fluid mb-3"
                        alt="A sample of dufuna's certificate of completion" />
                    <p class="text-center certification__sub-text">You can share your Certificate in the Certifications
                        section of your LinkedIn profile, on printed resumes, CVs, or other documents.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "CertificationSection",
    props: {
        partnerColor: String,
    },
    computed: {
        partnerTextColor() {
            const threshold = 186;
            const red = parseInt(this.partnerColor.slice(1, 3), 16);
            const green = parseInt(this.partnerColor.slice(3, 5), 16);
            const blue = parseInt(this.partnerColor.slice(5, 7), 16);
            const contrastRatio = ((red * .299) + (green * .587) + (blue * .114));
            const contrastColor = (contrastRatio >= threshold) ? '#000000' : '#ffffff';
            return contrastColor;
        }
    },
}
</script>