const selectionTestQuestions = (state) => {
    return state.selectionTestQuestions
}

const selectionTestSession = (state) => {
    return state.selectionTestSession;
}

const loadingScore = (state) => {
    return state.loadingNavigation
}

export default {
    selectionTestQuestions,
    selectionTestSession,
    loadingScore
}