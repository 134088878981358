import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "@/components/website-pages/LandingPage.vue";

const routes = [
    {
        path: "/",
        name: "LandingPage",
        component: LandingPage,
    },
    {
        path: "/login",
        name: "LoginHomePage",
        component: () =>
            import(
                /* webpackChunkName: "LoginHomePage" */ "@/components/elements/LoginHomePage.vue"
            ),
    },
    {
        path: "/faq",
        name: "FaqPage",
        component: () =>
            import(
                /* webpackChunkName: "FaqPage" */ "@/components/website-pages/FaqPage.vue"
            ),
    },
    {
        path: "/reset",
        name: "ResetPin",
        component: () =>
            import(
                /* webpackChunkName: "ResetPin" */ "@/components/resetPinFlow/ResetPin.vue"
            ),
        children: [
            {
                path: "validate-phone-number",
                name: "ValidatePhoneNumber",
                component: () =>
                    import(
                        /* webpackChunkName: "ValidatePhoneNumber" */ "@/components/resetPinFlow/ValidatePhoneNumber.vue"
                    ),
            },
            {
                path: "verify-otp",
                name: "VerifyOTP",
                component: () =>
                    import(
                        /* webpackChunkName: "VerifyOTP" */ "@/components/resetPinFlow/VerifyOTP.vue"
                    ),
            },
            {
                path: "create-pin",
                name: "CreatePIN",
                component: () =>
                    import(
                        /* webpackChunkName: "CreatePIN" */ "@/components/resetPinFlow/CreatePIN.vue"
                    ),
            },
            {
                path: "pin-reset-status",
                name: "PinResetStatus",
                component: () =>
                    import(
                        /* webpackChunkName: "PinResetStatus" */ "@/components/resetPinFlow/PinResetStatus.vue"
                    ),
            },
        ],
    },
    {
        path: "/account",
        name: "AccountPage",
        component: () =>
            import(
                /* webpackChunkName: "AccountPage" */ "../components/account/AccountPage.vue"
            ),
        children: [
            {
                path: "account-info",
                name: "AccountInfo",
                component: () =>
                    import(
                        /* webpackChunkName: "AccountInfo" */ "../components/account/AccountInfo.vue"
                    ),
            },
        ],
    },
    {
        path: "/courses",
        name: "CoursesPage",
        component: () =>
            import(
                /* webpackChunkName: "CoursesPage" */ "../components/views/CoursesPage.vue"
            ),
    },
    {
        path: "/certificates",
        name: "CertificatePage",
        component: () =>
            import(
                /* webpackChunkName: "CertificatePage" */ "../components/views/CertificatePage.vue"
            ),
    },
    {
        path: "/track",
        name: "TrackPage",
        component: () =>
            import(
                /* webpackChunkName: "TrackPage" */ "../components/views/TrackPage.vue"
            ),
        children: [
            {
                path: "intro/:id",
                name: "CourseIntroPage",
                component: () =>
                    import(
                        /* webpackChunkName: "CourseIntroPage" */ "../components/elements/CourseIntroPage.vue"
                    ),
            },
            {
                path: "video/:id",
                name: "VideoPage",
                component: () =>
                    import(
                        /* webpackChunkName: "VideoPage" */ "../components/elements/VideoPage.vue"
                    ),
            },
            {
                path: "quiz-intro/:id",
                name: "QuizIntro",
                component: () =>
                    import(
                        /* webpackChunkName: "QuizIntro" */ "../components/elements/QuizIntro.vue"
                    ),
            },
            {
                path: "quiz/:id",
                name: "QuizPage",
                component: () =>
                    import(
                        /* webpackChunkName: "QuizPage" */ "../components/views/QuizPage.vue"
                    ),
            },
            {
                path: "task/:id",
                name: "TaskPage",
                component: () =>
                    import(
                        /* webpackChunkName: "TaskPage" */ "../components/views/TaskPage.vue"
                    ),
            },
            {
                path: "quiz-list/:id",
                name: "QuizListPage",
                component: () =>
                    import(
                        /* webpackChunkName: "QuizListPage" */ "../components/elements/QuizListPage.vue"
                    ),
            },
            {
                path: "article/:id",
                name: "ArticlePage",
                component: () =>
                    import(
                        /* webpackChunkName: "ArticlePage" */ "../components/elements/ArticlePage.vue"
                    ),
            },
        ],
    },
    {
        path: "/selection-test/:id",
        name: "SelectionTest",
        component: () =>
            import(
                /* webpackChunkName: "SelectionTest" */ "../components/views/SelectionTest.vue"
            ),
    },
    {
        path: "/selection-quiz",
        name: "SelectionQuiz",
        component: () =>
            import(
                /* webpackChunkName: "SelectionQuiz" */ "../components/views/SelectionQuiz.vue"
            ),
    },
    {
        path: "/school/:id/:name?",
        name: "SchoolDetailsPage",
        component: () =>
            import(
                /* webpackChunkName: "SchoolDetailsPage" */ "../components/website-pages/SchoolDetailsPage.vue"
            ),
    },
    {
        path: "/track-info/:programName",
        name: "TrackDetailsPage",
        component: () =>
            import(
                /* webpackChunkName: "TrackDetailsPage" */ "../components/website-pages/TrackDetailsPage.vue"
            ),
    },
    {
        path: "/track-sign-up",
        name: "TrackApplicationPage",
        component: () =>
            import(
                /* webpackChunkName: "TrackApplicationPage" */ "../components/forms/registrations/TrackApplicationPage"
            ),
    },
    {
        path: "/school-sign-up",
        name: "SchoolApplicationPage",
        component: () =>
            import(
                /* webpackChunkName: "SchoolApplicationPage" */ "../components/forms/registrations/SchoolApplicationPage"
            ),
    },
    {
        path: "/track-application-success",
        name: "TrackApplicationSuccess",
        component: () =>
            import(
                /* webpackChunkName: "TrackApplicationSuccess" */ "../components/website-pages/TrackApplicationSuccess"
            ),
    },
    {
        path: "/school-application-success",
        name: "SchoolApplicationSuccess",
        component: () =>
            import(
                /* webpackChunkName: "SchoolApplicationSuccess" */ "../components/website-pages/SchoolApplicationSuccess"
            ),
    },
    {
        path: "/all-tracks",
        name: "TracksListPage",
        component: () =>
            import(
                /* webpackChunkName: "TracksListPage" */ "../components/website-pages/TracksListPage.vue"
            ),
    },
    {
        path: "/scholarships",
        name: "ScholarshipsPage",
        component: () =>
            import(
                /* webpackChunkName: "ScholarshipsPage" */ "../components/website-pages/ScholarshipsPage.vue"
            ),
    },
    {
        path: "/scholarships/scholarship-detail/:scholarshipName",
        name: "ScholarshipDetails",
        component: () =>
            import(
                /* webpackChunkName: "ScholarshipDetails" */ "../components/website-pages/ScholarshipDetails.vue"
            ),
    },
    {
        path: "/scholarships/:scholarshipName/:trackName",
        name: "ScholarshipTrackDetailsPage",
        component: () =>
            import(
                /* webpackChunkName: "TrackDetailsPage" */ "../components/website-pages/TrackDetailsPage.vue"
            ),
    },
    {
        path: "/scholarships/:scholarshipName/:trackName?/apply",
        name: "ScholarshipApplicationPage",
        component: () =>
            import(
                /* webpackChunkName: "ScholarshipApplicationPage" */ "../components/forms/registrations/ScholarshipApplicationPage"
            ),
        redirect: { name: "StartApplication" },
        children: [
            {
                path: "start-application",
                name: "StartApplication",
                component: () =>
                    import(
                        /* webpackChunkName: "PersonalInformation" */ "@/components/scholarshipApplicationFlow/StartApplication.vue"
                    ),
            },
            {
                path: "personal-information",
                name: "PersonalInformation",
                component: () =>
                    import(
                        /* webpackChunkName: "PersonalInformation" */ "@/components/scholarshipApplicationFlow/PersonalInformation.vue"
                    ),
            },
            {
                path: "educational-background",
                name: "EducationalBackground",
                component: () =>
                    import(
                        /* webpackChunkName: "EducationalBackground" */ "@/components/scholarshipApplicationFlow/EducationalBackground.vue"
                    ),
            },
            {
                path: "device-information",
                name: "DeviceInformation",
                component: () =>
                    import(
                        /* webpackChunkName: "DeviceInformation" */ "@/components/scholarshipApplicationFlow/DeviceInformation.vue"
                    ),
            },
            {
                path: "more-information",
                name: "MoreInformation",
                component: () =>
                    import(
                        /* webpackChunkName: "MoreInformation" */ "@/components/scholarshipApplicationFlow/MoreInformation.vue"
                    ),
            },
        ],
    },
    {
        path: "/partners",
        name: "PartnersPage",
        component: () =>
            import(
                /* webpackChunkName: "PartnersPage" */ "../components/partners/PartnersPage.vue"
            ),
    },
    {
        path: "/partners/school",
        name: "PartnersSchoolDetailsPage",
        component: () =>
            import(
                /* webpackChunkName: "SchoolDetailsPage" */ "../components/website-pages/SchoolDetailsPage.vue"
            ),
    },
    {
        path: "/partners/school-sign-up",
        name: "PartnersSchoolApplicationPage",
        component: () =>
            import(
                /* webpackChunkName: "SchoolApplicationPage" */ "../components/forms/registrations/SchoolApplicationPage"
            ),
    },
    {
        path: "/partners/school-application-success",
        name: "PartnersSchoolApplicationSuccess",
        component: () =>
            import(
                /* webpackChunkName: "SchoolApplicationSuccess" */ "../components/website-pages/SchoolApplicationSuccess"
            ),
    },
    {
        path: "/partners/track-info",
        name: "PartnersTrackDetailsPage",
        component: () =>
            import(
                /* webpackChunkName: "TrackDetailsPage" */ "../components/website-pages/TrackDetailsPage.vue"
            ),
    },
    {
        path: "/partners/track-sign-up",
        name: "PartnersTrackApplicationPage",
        component: () =>
            import(
                /* webpackChunkName: "TrackApplicationPage" */ "../components/forms/registrations/TrackApplicationPage"
            ),
    },
    {
        path: "/partners/track-application-success",
        name: "PartnersTrackApplicationSuccess",
        component: () =>
            import(
                /* webpackChunkName: "TrackApplicationSuccess" */ "../components/website-pages/TrackApplicationSuccess"
            ),
    },
    {
        path: "/track/dufuna-school/data-analytics",
        name: "DataAnalytics",
        component: () =>
            import(
                /* webpackChunkName: "DataAnalytics" */ "@/components/website-pages/DataAnalytics.vue"
            ),
    },
    {
        path: "/programme-sign-up",
        name: "DataAnalyticsApplication",
        component: () =>
            import(
                /* webpackChunkName: "DataAnalytics" */ "@/components/forms/registrations/DataAnalyticsApplication.vue"
            ),
    },
    {
        path: "/blog",
        name: "BlogPage",
        component: () =>
            import(
                /* webpackChunkName: "BlogPage" */ "../components/website-pages/BlogPage.vue"
            ),
    },
    {
        path: "/blog/:postId",
        name: "BlogDetailsPage",
        component: () =>
            import(
                /* webpackChunkName: "BlogDetailsPage" */ "../components/website-pages/BlogDetailsPage.vue"
            ),
    },

    { path: "/:catchAll(.*)", redirect: "/" },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    },
});

const MAX_IDLE_TIME = 120 * 60 * 1000; // 120 minutes in milliseconds
let idleTimer;

function startIdleTimer() {
    idleTimer = setTimeout(() => {
        localStorage.removeItem("vuex");
        router.push("/");
    }, MAX_IDLE_TIME);
}

function resetIdleTimer() {
    clearTimeout(idleTimer);
    startIdleTimer();
}

function handleUserActivity() {
    resetIdleTimer();
}

window.addEventListener("mousemove", handleUserActivity);
window.addEventListener("keypress", handleUserActivity);

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    // const accessiblePages = ['/', '/reset/validate-phone-number', '/reset/verify-otp', '/reset/create-pin'];
    // const authRequired = !accessiblePages.includes(to.path);
    // const loggedIn = localStorage.getItem('user');

    // if (authRequired && !loggedIn) {
    // 	return next('/');
    // }

    // next();
    resetIdleTimer();
    next();
});

export default router;
