import Repository from "./Repository";
import router from '@/router'

const resource = "applicant";
let config = {
    headers: {
        "Content-Type": "application/json",
    },
};

let login = (phoneNumber, password) => {
    let body = { phone: phoneNumber, pin: password };
    return Repository.post(`/applicant/login`, body, config);
};

let sendOtp = (phoneNumber) => {
    return Repository.post(`${resource}/${phoneNumber}/generate-otp`, config);
};

let verifyOtp = (otp) => {
    let body = { otp: otp };
    let scholarPhoneNumber = router.currentRoute.value.query.phoneNumber;
    return Repository.post(`${resource}/${scholarPhoneNumber}/verify-otp`, body, config);
};

let changePin = (scholarNewPin, accessToken) => {
    let params = { access_token: accessToken };
    let body = { pin: scholarNewPin };
    let scholarPhoneNumber = router.currentRoute.value.query.phone;
    return Repository.put(`${resource}/${scholarPhoneNumber}/pin/`, body, {params: params}, config);
};

export default {
    login,
    sendOtp,
    verifyOtp,
    changePin
};
