import index from "./index";

const setCustomerPhoneNumber = (state, CustomerPhoneNumber) => {
    state.CustomerPhoneNumber = CustomerPhoneNumber;
};

const resetAuthStates = (state) => {
    Object.assign(state, index.getDefaultAuthStates());
};

const updatePhoneNumber = (state, phoneNumber) => {
    state.phoneNumber = phoneNumber
};

const updateOtpSent = (state, sent) => {
    state.otpSent = sent
};

const updateOtpError = (state, otpError) => {
    state.otpError = otpError
};

const updateOtp = (state, otp) => {
    state.otp = otp
};

const updateOtpVerified = (state, verified) => {
    state.otpVerified = verified
};

const changePinMutation = (state, payload) => {
    state.otpVerified = payload
};

const updateCustomerEmail = (state, customerEmail) => {
    state.customerEmail = customerEmail
};


export default {
    setCustomerPhoneNumber,
    resetAuthStates,
    updatePhoneNumber,
    updateOtp,
    updateOtpError,
    updateOtpSent,
    updateOtpVerified,
    changePinMutation,
    updateCustomerEmail
};
