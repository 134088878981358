import Repository from "./Repository";

const resource = "lesson";
let config = {
    headers: {
        "Content-Type": "application/json",
    },
};

let lessonsVideos = (lessonsId) => {
    return Repository.get(`${resource}/${lessonsId}/lessons`, {}, config);
};

export default {
    lessonsVideos
};
