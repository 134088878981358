const updateError = (state, payload) => {
    state.error = {
        title: payload.title,
        message: payload.message,
    };
};

const updateAlert = (state, payload) => {
    if (payload) {
        state.alert = {
            title: payload.title,
            message: payload.message,
            buttons: payload.buttons,
        };
    } else {
        state.alert = payload;
    }
};
const updateToken = (state, token) => {
    state.token = token;
};

const setUserData = (state, user) => {
    state.user = user;
};

export default {
    setUserData,
    updateError,
    updateAlert,
    updateToken,
};
